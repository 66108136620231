export class AddressDTO {
	constructor(
		public id?: number,
		public street?: string,
		public city?: string,
		public postalCode?: string,
		public province?: string,
		public country?: string
	) {}
}
