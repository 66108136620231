import { Validators } from '@angular/forms';
import { RAMED_ENABLED } from '../global-variables';
import { concat } from 'lodash';

export class ProcedureCode {
	constructor(
		public id?: number,

		public code?: string,
		public description?: string,
		public duration?: number,
		public modality?: any,
		public bodyRegion?: any,
		public pharmaceutical?: any,
		public reasonForExam?: any,
		public billingCode?: any,
		public defaultPerformingPhysician?: any,
		public composed?: boolean,
		public templateModel?: any
	) {}
}

export interface EmailMessage {
	destAddress: string;
	subject: string;
	body: string;
}

export class ImagingCenter {
	constructor(
		public id?: string,
		public name?: string,
		public gmailPassword?: string,
		public communicatingEmail?: string,
		public oadc?: string,
		public smsUsername?: string,
		public smsPassword?: string,
		public arabicName?: string,
		public email?: string,
		public phone?: string,
		public fax?: string,
		public address?: string,
		public website?: string
	) {}
}

export const initEntity = {
	id: '',
	value: '',
	description: '',
};

export const initRequiredEntity = {
	id: ['', Validators.required],
	value: '',
	description: '',
};

export const imagingServiceRequestEntity = {
	id: '',
	version: '',
	accessionNumber: '',
	imagingServiceRequestComments: '',
	requestingPhysician: '',
	requestingPhysicianIdentification: '',
	requestingService: '',
	issuerOfAccessionNumberSequence: '', // array of 0 or 1 item
	issueDateOfTheImagingServiceRequest: [null],
	issueTimeOfTheImagingServiceRequest: '12:35',
	placerOrderNumber: '',
	orderPlacerIdentification: '',
	fillerOrderNumber: '',
	orderFillerIdentification: '',
	orderEnteredBy: null,
	orderEnterersLocation: '',
	orderCallBackPhoneNumber: '',
	admissionID: '',
	issuerOfAdmissionIDSequence: '', // array of oly 1 item
	requestedProcedure: '',
	imagingOrder: '',
	patient: '',
};

export const scheduledProcedureStepEntity = {
	id: '',
	version: '',
	scheduledStationAETitle: null,
	scheduledStationName: '',
	scheduledProcedureStepLocation: '',
	scheduledProcedureStepStartTime: '12:00',
	scheduledProcedureStepEndTime: '13:00',
	scheduledProcedureStepStartDate: [null],
	scheduledProcedureStepEndDate: [null],
	scheduledPerformingPhysiciansName: '',
	scheduledPerformingPhysicianIdentification: '',
	scheduledProcedureStepDescription: '',
	scheduledProtocolCodeSequence: [],
	scheduledProcedureStepID: '',
	scheduledProcedureStepStatus: '',
	commentsOnTheSPS: '',
	spsStatusColor: '',
	modality: null,
	requestedContrastAgent: '',
	preMedication: '',
	anatomicalOrientationType: '',
	performedProcedureStep: null,
	requestedProcedure: null,
	reportingTask: null,
	procedureCode: null,
	workflowItem: null,
	assistantPhysicians: '',
	numberOfFilms: '',
	contrast: '',
	patientAge: '',
	patientSex: '',
	accessionNumber: '',
	patientName: '',
	patientID: '',
	paymentID: '',
	studyInstanceUID: '',
	technician: '',
};

export const DEBITERS: string[] = concat(
	['PATIENT', 'INSURED_PATIENT', 'THIRD_PARTY_PAYMENT', 'PEC'],
	RAMED_ENABLED ? ['RAMED'] : []
);

export const ADMISSIONS: string[] = ['Interne', 'Externe'];

export const addressEntity = {
	country: '',
	province: '',
	city: '',
	street: '',
	postalCode: '',
};

export const PHARMACEUTICAL2: string[] = ['C+', 'C-'];

export const PHARMACEUTICAL: string[] = [
	'18F - NAF',
	'ACE INHIBITOR',
	'ANTISPASMODIC',
	'ARBUTAMINE',
	'AV - 45',
	'BARIUM',
	'BILIARY CONTRAST',
	'BLADDER CONTRAST',
	'CEA',
	'CHOLEGRAPHIC CONTRAST',
	'COLLECTING SYSTEM CONTRAST',
	'DIURETIC',
	'DOBUTAMINE',
	'DTPA',
	'ECD',
	'FISTULA CONTRAST',
	'FLORBETAPIR',
	'GALLIUM',
	'GLUCOHEPTONATE',
	'HIDA',
	'HIPPURAN',
	'HSA',
	'I - 123',
	'I - 123 MIBG',
	'I - 131',
	'I - 131 TOSITUMOMAB',
	'IN - 111',
	'IN - 111 CAPROMAB PENDETIDE',
	'IN - 111 PENTETREOTIDE',
	'IN - DTPA',
	'IN - WBC',
	'INTRA POUCH CONTRAST',
	'INTRAARTICULAR CONTRAST',
	'INTRADISCAL CONTRAST',
	'INTRAFALLOPIAN CONTRAST',
	'INTRALOOP CONTRAST',
	'INTRATHECAL CONTRAST',
	'INTRATHECAL CONTRAST VIA LUMBAR INJECTION',
	'INTRAUTERINE CONTRAST',
	'INTRAVENOUS CONTRAST',
	'INTRAVESICAL CONTRAST',
	'IPPA',
	'IVC CONTRAST',
	'MAA',
	'MAG3',
	'MDP',
	'MIBG',
	'MICROSPHERES',
	'MORPHINE',
	'NEPHROSTOMY CONTRAST',
	'ORAL CHOLECYSTOGRAPHIC CONTRAST',
	'ORAL CONTRAST',
	'PERSANTINE',
	'PLATELET PLASMA',
	'PYROPHOSPHATE',
	'Radiolabeled denatured RBC',
	'RADIUM',
	'RECTAL CONTRAST',
	'SALIVARY DUCT CONTRAST',
	'SAMARIUM - 153',
	'SEMINAL VESICLE CONTRAST',
	'SESTAMIBI',
	'SHUNT CONTRAST',
	'SIR - SPHERES',
	'SULFUR COLLOID',
	'T - TUBE CONTRAST',
	'TC - DTPA',
	'TC - HMPAO',
	'TC - O4',
	'TC - RBC',
	'TC - WBC',
	'TECHNIETIUM',
	'THALLIUM',
	'THERASPHERES',
	'THROMBOLYTIC',
	'URETERAL STENT CONTRAST',
	'URETHRAL CONTRAST',
	'WATER SOLUBLE CONTRAST',
	'WITH IV CONTRAST',
	'WITHOUT IV CONTRAST',
	'WITHOUT THEN WITH IV CONTRAST',
	'XENON',
	'Y - 90 IBRITUMOMAB TIUXETAN',
];

export const VIEW: string[] = [
	'AP',
	'AP SUPINE',
	'APICAL LORDOTIC',
	'AXILLARY',
	'BASILAR',
	'BENDING',
	'CONE',
	'CROSS TABLE LATERAL',
	'DECUBITUS',
	'ERECT',
	'EXPIRATION',
	'EXTENSION',
	'FLEXION',
	'INLET',
	'INSPIRATION',
	'JUDET',
	'LATERAL',
	'LEFT LATERAL',
	'LEFT LATERAL DECUBITUS',
	'LEFT OBLIQUE',
	'OBLIQUE',
	'OBLIQUES',
	'OUTLET',
	'PA',
	'PANORAMIC',
	'RIGHT DECUBITUS',
	'RIGHT LATERAL',
	'RIGHT LATERAL DECUBITUS',
	'RIGHT OBLIQUE',
	'SPOT',
	'SUBMENTOVERTICAL',
	'SUNRISE',
	'SUPINE',
	'SWIMMERS',
	'TRANSTHORACIC',
	'TUNNEL',
	'UPRIGHT',
	'WATERS',
];
