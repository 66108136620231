export class ReferringPhysicianDTO {
	constructor(
		public id?: number,
		public titleId?: number,
		public functionId?: number,
		public specialtyId?: number,
		public firstName?: string,
		public lastName?: string,
		public fullName?: string,
		public codeAnam?: string,
		public phone?: string,
		public email?: string,
		public primaryAddress?: string,
		public secondaryAddresses?: string
	) {}
}
