/**
 * Created by MBX on 7/6/2017.
 */
import moment from 'moment';

export class ReportFilter {
	constructor(
		public key: string = '',
		public dateRange: string = '',
		public reportStatus: string = '',
		public paymentStatus: string = '',
		public priority: string = '',
		public labels: string = ''
	) {
		const today = moment().format('YYYYMMDD');
		this.dateRange = `${today}-${today}`;
	}
}
