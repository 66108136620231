export class RendezVous {
	constructor(
		public patientID?: string,
		public accessionNumber?: string,
		public patientName?: string,
		public date?: any,
		public exam?: string,
		public observation?: string
	) {}
}
