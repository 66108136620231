export class StudyItem {
	constructor(
		public name: string,
		public url: string,
		public pacsPatientID: string = undefined,
		public studyInstanceUID: string = undefined,
		public studyAvailability: StudyAvailability = StudyAvailability.NOT_AVAILABLE,
		public studyItems: StudyItem[],
		public studyDateTime: any,
		public folder: boolean = true,
		public level: number = 1
	) {}
}

export enum StudyAvailability {
	AVAILABLE = 'AVAILABLE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
}
