export class HolidayDTO {
	constructor(
		public id?: number,
		public title?: string,
		public employeeName?: string,
		public employeeId?: number,
		public managerName?: string,
		public managerId?: number,
		public type: string = 'LEAVE', // LEAVE, HOLIDAY
		public status: string = 'PENDING', // PENDING, APPROVED, REJECTED
		public startDate?: any,
		public endDate?: any,
		public notes?: string,
		public imagingCenterId?: number
	) {}
}
