export class ContractFieldDTO {
	constructor(
		public name?: string,
		public id?: string,
		public parentFieldId: number = 0,
		public calculationBase: string = 'EXAM_PRICE',
		public partPrice: number = 0,
		public partPercentage: number = 0,
		public partType: string = 'PERCENTAGE',
		public minPrice: number = 0,
		public maxPrice: number = 0
	) {}
}
