export class PatientDTO {
	constructor(
		public id?: number,
		public externalPatientID?: string,
		public titleId?: number,
		public firstName?: string,
		public lastName?: string,
		public dateOfBirth?: any,
		public createdDate?: any,
		public updatedDate?: any,
		public genderId?: number,
		public maritalStatusId?: number,
		public confidentialityId: number = 1,
		public insuranceId?: number,
		public addressId?: number,
		public medicalHistoryId?: number,
		public demographicId?: number,
		public height: number = 0,
		public weight: number = 0,
		public imc: number = 0,
		public sc: number = 0,
		public cin?: string,
		public phone?: string,
		public email?: string,
		public nationalNumber?: string,
		public imageBase64?: string,
		public patientClassId: number = 3,
		public consultingDoctorId?: number,
		public debiter: string = 'PATIENT',
		public confidential?: boolean,
		public imagingCenterId?: number
	) {}
}
