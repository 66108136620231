export class ProcedureCodeDTO {
	constructor(
		public id?: number,
		public code?: string,
		public description?: string,
		public longDescription?: string,
		public composed: boolean = false,
		public risCode?: string,
		public duration: number = 10,
		public procedureCatalogId?: number,
		public defaultPerformingPhysicianId?: number,
		public billingCodeId?: number,
		public billingCode?: string,
		public billingCodeDescription?: string,
		public billingCodePrice: number = 0.0,
		public templateModelId: number = 10010,
		public reasonForExamId?: number,
		public pharmaceutical?: string,
		public templateModelName?: string,
		public reasonForExamColor?: string,
		public reasonForExamValue?: string,
		public modalityId?: number,
		public modalityValue?: string,
		public imagingCenterId?: number
	) {}
}
