export class MedicalHistoryDTO {
	constructor(
		public id?: number,
		public additionalPatientHistory?: string,
		public medicalAlerts?: string,
		public specialNeeds?: string,
		public observations?: string,
		public allergies?: any,
		public patientStateId?: number,
		public dangerCodeId?: number
	) {}
}
