export interface TableConfig {
	id: number;
	name: string;
	userId: number;
	tableColumns: TableColumn[];
	formattingRules: FormattingRule[];
}

export class TableColumn {
	constructor(
		public id: number,
		public label: string,
		public header: string,
		public value: string,
		public type: ColumnDataType = ColumnDataType.STRING,
		public unit: string,
		public defaultValue: string,
		public sortField: string,
		public sortable: boolean,
		public searchable: boolean,
		public hidden: boolean,
		public selected: boolean,
		public available?: boolean,
		public required?: boolean,
		public order?: number
	) {}
}

export interface FormattingStyle {
	id: number;
	bold: boolean;
	italic: boolean;
	underlined: boolean;
	strikethrough: boolean;
	color: string;
	backgroundColor: string;
	displayMode: DisplayMode;
	icon: string;
	badge: string;
}

export enum FormatRule {
	IS_EMPTY = 'IS_EMPTY',
	IS_NOT_EMPTY = 'IS_NOT_EMPTY',
	TEXT_CONTAINS = 'TEXT_CONTAINS',
	TEXT_NOT_CONTAINS = 'TEXT_NOT_CONTAINS',
	TEXT_STARTS_WITH = 'TEXT_STARTS_WITH',
	TEXT_ENDS_WITH = 'TEXT_ENDS_WITH',
	TEXT_IS_EXACTLY = 'TEXT_IS_EXACTLY',
	DATE_IS = 'DATE_IS',
	DATE_IS_BEFORE = 'DATE_IS_BEFORE',
	DATE_IS_AFTER = 'DATE_IS_AFTER',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
	LESS_THAN = 'LESS_THAN',
	LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
	IS_EQUAL = 'IS_EQUAL',
	IS_NOT_EQUAL = 'IS_NOT_EQUAL',
	IS_BETWEEN = 'IS_BETWEEN',
	IS_NOT_BETWEEN = 'IS_NOT_BETWEEN',
}

export enum DisplayMode {
	TEXT = 'TEXT',
	ICON = 'ICON',
	BADGE = 'BADGE',
}

export enum ColumnDataType {
	DATE = 'DATE',
	STRING = 'STRING',
	NUMERIC = 'NUMERIC',
	BOOLEAN = 'BOOLEAN',
	STATUS = 'STATUS',
}

export interface FormattingRule {
	id: number;
	appliedTo: 'ROW' | 'COLUMN';
	targetColumn: string;
	formatRule: FormatRule;
	primaryFormatValue: string;
	secondaryFormatValue: string;
	formattingStyle: FormattingStyle;
	order: number;
}

const defaultFormattingStyle: FormattingStyle = {
	id: null,
	bold: false,
	italic: false,
	underlined: false,
	strikethrough: false,
	backgroundColor: '#ffffff',
	color: '#1689a2',
	displayMode: DisplayMode.TEXT,
	icon: '',
	badge: '',
};

export const defaultFormattingRule: FormattingRule = {
	id: null,
	appliedTo: 'COLUMN',
	targetColumn: null,
	formatRule: FormatRule.IS_NOT_EMPTY,
	primaryFormatValue: '',
	secondaryFormatValue: '',
	formattingStyle: defaultFormattingStyle,
	order: 0,
};

export const FORMAT_RULES: FormatRule[] = [
	FormatRule.IS_EMPTY,
	FormatRule.IS_NOT_EMPTY,
	FormatRule.TEXT_CONTAINS,
	FormatRule.TEXT_NOT_CONTAINS,
	FormatRule.TEXT_STARTS_WITH,
	FormatRule.TEXT_ENDS_WITH,
	FormatRule.TEXT_IS_EXACTLY,
	FormatRule.DATE_IS,
	FormatRule.DATE_IS_BEFORE,
	FormatRule.DATE_IS_AFTER,
	FormatRule.GREATER_THAN,
	FormatRule.GREATER_THAN_OR_EQUAL,
	FormatRule.LESS_THAN,
	FormatRule.LESS_THAN_OR_EQUAL,
	FormatRule.IS_EQUAL,
	FormatRule.IS_NOT_EQUAL,
	FormatRule.IS_BETWEEN,
	FormatRule.IS_NOT_BETWEEN,
];

export const DEFAULT_DATE_OPTIONS = [
	'TODAY',
	'TOMORROW',
	'YESTERDAY',
	'PAST_WEEK',
	'PAST_MONTH',
	'PAST_YEAR',
];

export const DISPLAY_MODES = ['TEXT', 'ICON', 'BADGE'];
