import { ContractFieldDTO } from './contract-field';

export class StaffContractDTO {
	constructor(
		public id?: string,
		public name?: string,
		public fee: number = 0,
		public feeType: string = 'EXAM',
		public calculationBase: string = 'EXAM_PRICE',
		public partPrice: number = 0,
		public partPercentage: number = 0,
		public partType: string = 'PERCENTAGE',
		public minPrice: number = 0,
		public maxPrice: number = 0,
		public examTypes: ContractFieldDTO[] = [],
		public procedureCodes: ContractFieldDTO[] = []
	) {}
}
