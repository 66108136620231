export class UserAccountDTO {
	constructor(
		public id?: number,
		public enabled: boolean = true,
		public password?: string,
		public username?: string,
		public identifier?: string,
		public firstName?: string,
		public lastName?: string,
		public fullName?: string,
		public clinicalID?: string,
		public phone?: string,
		public email?: string,
		public codeAnam: string = '000',
		public address?: string,
		public secondaryAddresses?: string,
		public titleId?: number,
		public boxId?: number,
		public specialityId?: number,
		public color?: string,
		public canViewConfidentialData: boolean = false,
		public functionId?: number,
		public contractId?: number,
		public profileId?: number,
		public uiLanguage: string = 'fr',
		public imagingCenterId: number = 1
	) {}
}
