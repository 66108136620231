import moment from 'moment';

export class ImagingExamDTO {
	constructor(
		public orderId?: number,
		public reasonForExamId?: number,
		public procedureCodeId?: number,
		public aetId?: number,
		public technicianId?: number,
		public referringPhysicianId?: number,
		public performingPhysicianId?: number,
		public templateModelId: number = 10010,
		public priorityId: number = 262,
		public duration: number = 15,
		public date: any = moment().utc(true),
		public time: any = moment().utc(true).format('HH:mm'),
		public prescriptionDate: any = moment().subtract(1, 'day'),
		public accessionNumber?: string,
		public attachedAccessionNumbers?: string,
		public patientPart: number = 100.0,
		public organismPart: number = 0.0,
		public totalAmount: number = 0.0,
		public discount: number = 0.0,
		public percentageDiscount: number = 0.0,
		public notes?: string,
		public commentsOnTheSPS?: string,
		public isrId?: number,
		public spsId?: number,
		public rpId?: number,
		public reportingTaskId?: number,
		public tariffId?: number,
		public instanceAvailable: boolean = false,
		public pecId?: number,
		public pecRef?: string,
		public workflowId?: number,
		public studyInstanceUID?: string,
		public admissionNumber?: string,
		public scheduledProcedureStepID?: string
	) {}
}
