/**
 * Created by MBX on 7/6/2017.
 */
import moment from 'moment';

export class WorkflowFilter {
	constructor(
		public key: string = '',
		public dateRange: string = '',
		public reportStatuses: string = 'ALL',
		public patientStatuses: string = 'ALL',
		public paymentStatuses: string = 'ALL',
		public modalities: string = 'ALL',
		public physicianId: number = null,
		public technicianId: number = null,
		public hasImages: boolean = false
	) {
		const today = moment().format('YYYYMMDD');
		this.dateRange = `${today}-${today}`;
	}
}
