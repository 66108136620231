export class Profile {
	constructor(
		public id?: 0,
		public name?: string,
		public description?: string,
		public admin?: false,
		public deleted?: false,
		public defaultRoute?: string,
		public primaryCalSideCard: string = 'EXAM',
		public managePayment?: false,
		public canDeletePayment?: false,
		// Patient authorizations
		public loggingModule?: false,
		public patientModule?: false,
		public physicianModule?: false,
		public viewExams: string = 'NONE',
		public editPhysician: string = 'NONE',
		public deletePhysician: string = 'NONE',
		public exportPhysicians: string = 'NONE',
		public managePermissions: string = 'NONE',
		public createPatient: string = 'NONE',
		public updatePatient: string = 'NONE',
		public deletePatient: string = 'NONE',
		public exportPatient: string = 'NONE',
		public downloadPatientFiles: string = 'NONE',
		// Scheduler authorizations
		public schedulerModule?: false,
		public createOrder: string = 'NONE',
		public updateOrder: string = 'NONE',
		public cancelOrder: string = 'NONE',
		public scheduleRequestedOrder: string = 'NONE',
		public scheduleExam: string = 'NONE',
		public searchExam: string = 'NONE',
		public authorizeExam: string = 'NONE',
		public viewRequestedOrders: string = 'NONE',
		public editExam: string = 'NONE',
		public viewOrdersInProgress: string = 'NONE',
		public printExamsList: string = 'NONE',
		public startExam: string = 'NONE',
		public finishExam: string = 'NONE',
		public exitPatient: string = 'NONE',
		public createRdvForResult: string = 'NONE',
		public printRdvForExam: string = 'NONE',
		public viewPatientFolderCalendar: string = 'NONE',
		public viewReports: string = 'NONE',
		public completeExam: string = 'NONE',
		public sendSms: string = 'NONE',
		// Reporting authorization
		public reportingModule?: false,
		public createReport: string = 'NONE',
		public dictateReport: string = 'NONE',
		public transcribeReport: string = 'NONE',
		public verifyReport: string = 'NONE',
		public signReport: string = 'NONE',
		public compareReport: string = 'NONE',
		public reviewReport: string = 'NONE',
		public exportReport: string = 'NONE',
		public deleteReport: string = 'NONE',
		public printReport: string = 'NONE',
		public shareReport: string = 'NONE',
		public launchViewer: string = 'NONE',
		public viewImages: string = 'NONE',
		public editReport: string = 'NONE',
		public editSignedReport: string = 'NONE',
		// Statistic
		public statisticModule?: false,
		public exportStatistic: string = 'NONE',
		public viewStatistic: string = 'NONE',
		public showPivotTable: string = 'NONE',
		public showPerformingPhysicianStats: string = 'NONE',
		public showTechnicianStats: string = 'NONE',
		public showAetStats: string = 'NONE',
		public showPaymentStats: string = 'NONE',
		public showExamTypeStats: string = 'NONE',
		public showPatientStats: string = 'NONE',
		public showReferringPhysicianStats: string = 'NONE',
		// Workflow authorization
		public workflowModule?: false,
		public viewPatientFolder: string = 'NONE',
		public viewPatientReport: string = 'NONE',
		public viewPatientStudies: string = 'NONE',
		public manageExam: string = 'NONE',
		public createPrescription: string = 'NONE',
		// Setting authorization
		public settingModule?: false,
		public managePathologies: string = 'NONE',
		public manageDictionaries: string = 'NONE',
		public manageUsers: string = 'NONE',
		public manageAETs: string = 'NONE',
		public manageModalities: string = 'NONE',
		public manageNetwork: string = 'NONE',
		public manageProfile: string = 'NONE',
		public manageProcedureCodes: string = 'NONE',
		public manageCenter: string = 'NONE',
		public manageReportTemplates: string = 'NONE',
		public manageOrderPriorities: string = 'NONE',
		public manageExamStatus: string = 'NONE',
		public manageCalendarSetting: string = 'NONE',
		public manageHolidays: string = 'NONE',
		public manageRooms: string = 'NONE',
		public printingModule: boolean = true,
		public printBooklet: string = 'ALL',
		public printPaymentReceipt: string = 'ALL',
		public printThermalTicket: string = 'ALL',
		public printTaskList: string = 'ALL',
		public printAttestation: string = 'ALL',
		public printAppointmentForResult: string = 'ALL',
		public printAppointmentList: string = 'ALL'
	) {}
}
