export class QueryParam {
	constructor(
		public studyDate: string = '',
		public studyDescription: string = '',
		public studyInstanceUID: string = '',
		public accessionNumber: string = '',
		public patientName: string = '',
		public patientID: string = '',
		public patientBirthDate: string = '',
		public patientSex: string = '',
		public modalitiesInStudy: string = '',
		public patientComments: string = '',
		public studyTime: string = '',
		public referringPhysicianName: string = '',
		public additionalPatientHistory: string = '',
		public patientSize: string = '',
		public patientWeight: string = '',
		public numberOfPatientRelatedStudies: string = ''
	) {}

	validQuery(): boolean {
		return (
			this.patientName !== '' ||
			this.studyDate !== '' ||
			this.studyDescription !== '' ||
			this.studyInstanceUID !== '' ||
			this.accessionNumber !== ''
		);
	}
}
