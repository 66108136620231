import { PatientDTO } from './patient';
import { ImagingExamDTO } from './imaging-exam';
import { AddressDTO } from './address';
import { InsuranceDTO } from './insurance';
import { PaymentDTO } from './payment';
import { MedicalHistoryDTO } from './medical-history';

export class ExamDetailDTO {
	constructor(
		public patient: PatientDTO,
		public patientAddress: AddressDTO,
		public insurance?: InsuranceDTO,
		public imagingExams: ImagingExamDTO[] = [],
		public payment?: PaymentDTO,
		public medicalHistory?: MedicalHistoryDTO,
		public placerOrderNumber?: string,
		public visitNumber?: string
	) {}
}
