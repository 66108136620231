export class LabelDTO {
	constructor(
		public id?: number,
		public value: string = '',
		public description?: string,
		public color?: string,
		public module: string = 'ALL',
		public imagingCenterId?: number
	) {}
}
