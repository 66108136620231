export class InsuranceDTO {
	constructor(
		public id?: number,
		public name?: string,
		public code?: string,
		public relationWithInsured: string = 'HIMSELF',
		public organismId?: string,
		public organismName?: string,
		public conventionName?: string,
		public conventionId?: number,
		public patientPart: number = 100.0,
		public organismPart: number = 0.0,
		public insuredFirstName?: string,
		public insuredLastName?: string,
		public insuredTitle?: string,
		public coverNumber?: string,
		public pecName?: string,
		public address?: string
	) {}
}
