export interface PrintHistory {
	id: number;
	notes: string;
	targetDocument: TargetDocument;
	userFullName: string;
	dateTime: any;
	documentId: string;
}

export enum TargetDocument {
	BOOKLET = 'BOOKLET',
	A4_REPORT = 'A4_REPORT',
	PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',
	ATTESTATION = 'ATTESTATION',
	DAILY_STATE = 'DAILY_STATE',
	ANTERIOR_STATE = 'ANTERIOR_STATE',
	PATIENTS_STATE = 'PATIENTS_STATE',
	THERMAL_TICKET = 'THERMAL_TICKET',
	RDV_FOR_RESULT = 'RDV_FOR_RESULT',
	TASKS_LIST = 'TASKS_LIST',
}
