export class AppointmentDTO {
	static [Symbol.hasInstance](obj) {
		return obj.appointmentReason && obj.appointmentStatus;
	}
	constructor(
		public id?: number,
		public comments?: string,
		public urgent: boolean = false,
		public scheduled: boolean = false,
		public waiting: boolean = true,
		public recurrent: boolean = false,
		public startTime?: any,
		public endTime?: any,
		public startDate?: any,
		public endDate?: any,
		public duration: number = 10,
		public creationDate?: any,
		public appointmentReason?: string,
		public appointmentReasonId?: number,
		public appointmentStatus: string = 'waiting_list',
		public createdBy?: string,
		public appointmentType: string = 'INTERNAL',
		public appointmentSource?: string,
		public physicianId?: number,
		public aetId?: number,
		public roomId?: number,
		public referringPhysicianId?: number,
		public referringPhysicianFullName?: string,
		public patientId?: number,
		public priorityId: number = 262,
		public cancellationReason?: string,
		public cancellationDate?: any,
		public cancelledBy?: string,
		public examType?: string,
		public examTypeId?: number,
		public modality?: string,
		public labels?: any,
		public patientName?: string,
		public imagingCenterId?: number,
		public placerOrderNumber?: string,
		public visitNumber?: string
	) {}
}
