export class CalendarSettingDTO {
	constructor(
		public id: number = 0,
		public openingTime: any = '2023-08-31 08:00:00',
		public closingTime: any = '2023-08-31 18:00:00',
		public closingDays: string = 'D7',
		public defaultView: string = 'W',
		public minTimeSlot: number = 15,
		public imagingCenterId: number = 1
	) {}
}
