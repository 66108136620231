/**
 * Created by MBX on 7/3/2017.
 */

export class AVSC {
	constructor(
		public staffId?: number,
		public aetId?: number,
		public date?: any,
		public start?: any,
		public duration?: number,
		public end?: any
	) {}
}

export interface Availability {
	aet: string;
	staff: string;
	timeSlots: TimeSlot[];
}

export interface TimeSlot {
	date: Date;
	start: string;
	end: string;
	duration: number;
}
