import moment from 'moment';

export class CalendarEvent {
	constructor(
		public id = 0,
		public title = 'No Title',
		public timeText = '',
		public start: any = moment().format('YYYY-MM-DD HH:mm'),
		public end: any = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm'),
		public borderColor = '#915f7b',
		public backgroundColor = '#915f7b',
		public constraint = 'businessHours',
		public resourceId?: string,
		public isrId?: string,
		public spsId?: number,
		public rpId?: string,
		public patientId?: string,
		public patientID?: string,
		public patientName?: string,
		public resource?: string,
		public className?: string,
		public selected?: boolean,
		public overlap?: string,
		public color?: string,
		public textColor?: string,
		public resourceColor?: string,
		public orderStatus?: string,
		public performingPhysician?: string,
		public aet?: string,
		public paymentStatus?: string,
		public urgent = false,
		public confidential = false,
		public spsStatus?: string
	) {}
}
