export class ReportParagraph {
	constructor(
		public id: number = null,
		public title: string = '',
		public procedureType: string = null,
		public procedureCode: string = null,
		public text: string = '',
		public createdOn: any = null,
		public deleted: boolean = false
	) {}
}
