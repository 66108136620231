export class ReasonForExamDTO {
	constructor(
		public id?: number,
		public value?: string,
		public color?: string,
		public description?: string,
		public reportOption: string = 'WITH',
		public daysBeforeExamResult: number = 1
	) {}
}
