export class TemplateModelDTO {
	constructor(
		public id: number = 0,
		public name: string = '',
		public documentVersion: string = '0',
		public category: string = 'NONE',
		public formTemplate: string = null,
		public listType: string = 'STD',
		public examCategory: string = '',
		public ownerId: number = null,
		public mine: boolean = false,
		public imagingCenterId: number = 1
	) {}
}
