import { uniqueId } from 'lodash';

const UPLOAD_INSTANCE_ID_PREFIX = 'doc-';

export class UploadInstance {
	public id: any;
	public name: string;
	public fullPath: string;
	public size: number;
	public lastModified: any;

	private _progress = 0;

	constructor(
		public file: File,
		public index: number,
		fullPath: string
	) {
		this.id = uniqueId(UPLOAD_INSTANCE_ID_PREFIX);

		this.index += 1;
		this.name = file.name;
		this.size = file.size;
		this.lastModified = file.lastModified;
		this.fullPath = fullPath;
	}

	public get progress() {
		return this._progress;
	}

	public set progress(value) {
		this._progress = value;
		this.id = uniqueId(UPLOAD_INSTANCE_ID_PREFIX);
	}
}
