import moment from 'moment';
import { v4 } from 'uuid';

export class Payment {
	constructor(
		public id?: number,
		public paymentID: string = v4(),
		public reference?: string,
		public enteredAmount: number = 0.0,
		public baseAmount: number = 0.0,
		public organismPartPercentage: number = 0.0,
		public conventionName?: string,
		public organismName?: string,
		public organismCode?: string,
		public organismPart: number = 0.0,
		public patientPart: number = 0.0,
		public due: number = 0.0,
		public totalAfterDiscount: number = 0.0,
		public discount: number = 0.0,
		public discountPercentage: number = 0.0,
		public date: any = moment().utc(true),
		public dueDate: any = moment().utc(true),
		public cancelled: boolean = false,
		public locked: boolean = false,
		public notes?: string,
		public paymentMethod?: any,
		public paymentStatus: string = 'NOT_PAID',
		public bank?: any,
		public organism?: any,
		public otherPayer?: string,
		public payerID?: string,
		public patientID?: string,
		public patientName?: string,
		public paymentDispense?: boolean,
		public payer: Payer = Payer.PATIENT,
		public paymentItems?: PaymentItem[]
	) {}
}

export class PaymentItem {
	constructor(
		public id?: number,
		public procedureCode?: string,
		public accessionNumber?: string,
		public studyInstanceUID?: string,
		public status?: string,
		public quantity: number = 1,
		public discount: number = 0.0,
		public percentageDiscount: number = 0.0,
		public price: number = 0.0,
		public paidAmount: number = 0.0,
		public leftAmount: number = 0.0,
		public paymentID?: string,
		public initialPrice: number = 0.0,
		public patientPart: number = 100.0,
		public organismPart: number = 0.0,
		public organismName?: string,
		public conventionName?: string,
		public pecId?: number,
		public pecRef?: string,
		public paymentType: string = Payer.PATIENT
	) {}
}

export enum Payer {
	PATIENT = 'PATIENT',
	THIRD_PAYER = 'THIRD_PAYER',
	OTHER = 'OTHER',
}

export enum PaymentStatus {
	PAID = 'PAID',
	PAID_PARTIALLY = 'PAID_PARTIALLY',
	NOT_PAID = 'NOT_PAID',
	EXEMPT = 'EXEMPT',
}
