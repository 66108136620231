import moment from 'moment';
import { v4 } from 'uuid';
import { Payer } from '../payment';
import { PaymentItemDTO } from './payment-item';

export class PaymentDTO {
	constructor(
		public id?: number,
		public paymentID: string = v4(),
		public reference?: string,
		public enteredAmount: number = 0.0,
		public baseAmount: number = 0.0,
		public organismPartPercentage: number = 0.0,
		public conventionName?: string,
		public organismName?: string,
		public organismCode?: string,
		public organismPart: number = 0.0,
		public patientPart: number = 0.0,
		public due: number = 0.0,
		public totalAfterDiscount: number = 0.0,
		public discount: number = 0.0,
		public discountPercentage: number = 0.0,
		public date: any = moment().utc(true),
		public dueDate: any = moment().utc(true),
		public cancelled: boolean = false,
		public locked: boolean = false,
		public notes?: string,
		public paymentMethodId: number = 1,
		public paymentStatus: string = 'NOT_PAID',
		public bankId?: number,
		public organismId?: number,
		public otherPayer?: string,
		public payerID?: string,
		public patientID?: string,
		public patientName?: string,
		public paymentDispense?: boolean,
		public payer: Payer = Payer.PATIENT,
		public paymentItems?: PaymentItemDTO[],
		public imagingCenterId?: number
	) {}
}
