export class AetDTO {
	constructor(
		public id: number = null,
		public title: string = null,
		public name: string = null,
		public modalityId: number = null,
		public modalityName: string = null,
		public defaultTechnicianId: number = null,
		public defaultTechnicianName: string = null,
		public imagingCenterId: number = 1,
		public imagingCenterName: string = null,
		public hostname: string = null,
		public color: string = null,
		public room: string = null
	) {}
}

export class AetFullDTO {
	constructor(
		public id: number = null,
		public hostname: string = null,
		public title: string = null,
		public setAsDefault: boolean = false,
		public enabled: boolean = true,
		public modalityId: number = null,
		public color: string = null,
		public room: number = null,
		public defaultTechnicianId: number = null,
		public location: string = null,
		public hasMPPS: boolean = false,
		public name: string = '',
		public dateOfPurchase: any = null,
		public installationDate: any = null,
		public installer: string = '',
		public manufacturer: string = '',
		public imagingCenterId: number = 1
	) {}
}
